import React from "react";
import {Match, Team, User} from "@austenke/due-process-stat-parser";

export function TeamView(props: any) {
    let match: Match = props.match;
    let team: Team = props.team;

    let users: any = {};
    Object.keys(team.accountIds).map(Number).forEach((entityId: number) => {
        let accountId = team.accountIds[entityId];
        if (users.hasOwnProperty(accountId)) {
            users[accountId].push(entityId);
        } else {
            users[accountId] = [entityId];
        }
    });

    return (
        <React.Fragment>
            {Object.keys(users)
                .map((accountId: string) => {
                    let user = new User();
                    users[accountId].forEach((entityId: number) => user.addStats(match.users[entityId]));
                    return {
                        user: user,
                        entityId: users[accountId][0]
                    }
                })
                .sort((user1, user2) => {
                    let user1Damage = user1.user.atkDamageDealt + user1.user.defDamageDealt;
                    let user2Damage = user2.user.atkDamageDealt + user2.user.defDamageDealt;
                    return user1Damage - user2Damage || user1.user.kastCount - user2.user.kastCount;
                })
                .reverse()
                .map((userInfo: any) => {
                    let user: User = userInfo.user;
                    let name = team.userNames[userInfo.entityId];
                    if (name.length > 33) {
                        name = name.substring(0, 30) + "...";
                    }
                    let kills = user?.kills || 0;
                    let assists = user?.assists || 0;
                    let deaths = user?.deaths || 0;
                    let atkDamageDealt = user?.atkDamageDealt || 0;
                    let defDamageDealt = user?.defDamageDealt || 0;
                    let kastCount = user?.kastCount || 0;
                    let openingKills = user?.openingKills || 0;
                    let openingKillAttempts = user?.openingKillAttempts || 0;
                    let favoriteWeapon = User.getFavoriteWeapon(user) || "N/A";
                    let atkRoundsPlayed = user?.atkRoundsPlayed || 1;
                    let defRoundsPlayed = user?.defRoundsPlayed || 1;
                    return (
                        <tr key={userInfo.entityId + "-stats"}>
                            <td style={{verticalAlign: "middle", width: '20%', fontWeight: 500}}>{name}</td>
                            <td style={{verticalAlign: "middle", fontWeight: 500}}>{Math.floor(atkDamageDealt + defDamageDealt)}</td>
                            <td style={{verticalAlign: "middle", fontWeight: 500}}>{Math.floor(atkDamageDealt / atkRoundsPlayed)}  -  {Math.floor(defDamageDealt / defRoundsPlayed)}</td>
                            <td style={{verticalAlign: "middle", fontWeight: 500}}>{kills}  -  {deaths}  -  {assists}</td>
                            <td style={{verticalAlign: "middle", fontWeight: 500}}>{((kastCount / (atkRoundsPlayed + defRoundsPlayed)) * 100).toFixed(0)}%</td>
                            <td style={{verticalAlign: "middle", fontWeight: 500}}>{openingKills} / {openingKillAttempts}</td>
                            <td style={{verticalAlign: "middle", fontWeight: 500}}>{favoriteWeapon}</td>
                        </tr>
                    )
                })}
        </React.Fragment>
    )
}