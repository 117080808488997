import {
    Badge,
    Card,
    Col,
    Container,
    Row,
    Accordion,
    AccordionContext, Alert, Table, OverlayTrigger, Tooltip,
} from "react-bootstrap";
import React, {useContext} from "react";
import {TeamView} from "../teamView/TeamView";
import {QuestionCircleFill} from "react-bootstrap-icons";
import {Team, Match} from "@austenke/due-process-stat-parser";

export function MatchCard(props: any) {

    const match: Match = props.match;

    function getVariant(team: Team) {
        let winning;
        if (team.teamNumber === 1) {
            winning = match.team1.wins > match.team2.wins;
        } else {
            winning = match.team2.wins > match.team1.wins;
        }
        return winning ? "success" : "danger";
    }

    function getColor(team: Team) {
        let winning;
        if (team.teamNumber === 1) {
            winning = match.team1.wins > match.team2.wins;
        } else {
            winning = match.team2.wins > match.team1.wins;
        }
        return winning ? "green" : "red";
    }

    function TipADR() {
        return (
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id="tip-adr">
                        Attack ADR - Defense ADR
                    </Tooltip>
                }
            >
                <QuestionCircleFill className="ml-1"/>
            </OverlayTrigger>
        )
    }

    function TipKAST() {
        return (
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id="tip-kast">
                        Percentage of rounds in which player got a kill, assist, saved, or was traded
                    </Tooltip>
                }
            >
                <QuestionCircleFill className="ml-1"/>
            </OverlayTrigger>
        )
    }

    function TipOpeningDuels() {
        return (
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id="tip-opening">
                        Opening Kills / Attempts
                    </Tooltip>
                }
            >
                <QuestionCircleFill className="ml-1"/>
            </OverlayTrigger>
        )
    }

    function TipBestGun() {
        return (
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id="tip-best-gun">
                        Determined by accumulating the damage dealt by player with each weapon as a
                        percentage of the enemy's total health (100 or 150)
                    </Tooltip>
                }
            >
                <QuestionCircleFill className="ml-1"/>
            </OverlayTrigger>
        )
    }

    const currentEventKey = useContext(AccordionContext);

    return (
        <Card style={{background: 'white', borderRadius: '.25rem'}}>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey={match.matchId.toString()} style={{cursor: 'pointer'}}>
                <Container>
                    <Row className="align-items-center" style={{marginTop: '1%'}}>
                        <Col xs="1" sm="1">
                            <Badge variant={getVariant(match.team1)} className="float-left" style={{fontSize: '2.5rem'}}>{match.team1.wins}</Badge>
                        </Col>
                        <Col xs="5" sm="5">
                            <Row className="justify-content-center" style={{color: getColor(match.team1)}}>
                                <h1 style={{marginBottom: 0}}>{match.team1.name}</h1>
                            </Row>
                        </Col>
                        <Col xs="5" sm="5">
                            <Row className="justify-content-center" style={{color: getColor(match.team2)}}>
                                <h1 style={{marginBottom: 0}}>{match.team2.name}</h1>
                            </Row>
                        </Col>
                        <Col xs="1" sm="1">
                            <Badge variant={getVariant(match.team2)} className="float-right" style={{fontSize: '2.5rem'}}>{match.team2.wins}</Badge>
                        </Col>
                    </Row>
                    <p className="mt-1 mb-0" style={{fontSize: '.5rem'}}>Click to {currentEventKey === match.matchId.toString() ? "minimize" : "expand"}</p>
                </Container>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={match.matchId.toString()}>
                <Card.Body>
                    { props.match.roundCount !== (props.match.team1.wins + props.match.team2.wins) &&
                        <Alert variant='danger'>
                            Incomplete match detected. Your log file only contains stats
                            for {props.match.roundCount}/{props.match.team1.wins + props.match.team2.wins} rounds.
                        </Alert>
                    }
                    <Table striped bordered hover style={{height: '80vh',marginBottom: 0}}>
                        <thead>
                            <tr style={{borderBottom: '3px solid black'}}>
                                <th style={{width: '20%'}}>Name</th>
                                <th><div className="d-flex align-items-center justify-content-center" style={{fontWeight: 700}}>Damage</div></th>
                                <th><div className="d-flex align-items-center justify-content-center" style={{fontWeight: 700}}>ADR <TipADR/></div></th>
                                <th><div className="d-flex align-items-center justify-content-center" style={{fontWeight: 700}}>K - D - A</div></th>
                                <th><div className="d-flex align-items-center justify-content-center" style={{fontWeight: 700}}>KAST <TipKAST/></div></th>
                                <th><div className="d-flex align-items-center justify-content-center" style={{fontWeight: 700}}>Opening Duels <TipOpeningDuels/></div></th>
                                <th><div className="d-flex align-items-center justify-content-center" style={{fontWeight: 700}}>Best Weapon <TipBestGun/></div></th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize: "18px"}}>
                        <TeamView match={match} team={match.team1} name={match.team1.name}/>
                        <tr style={{borderBottom: '1px solid black', backgroundColor: 'white'}} className="no-hover">
                            <td colSpan={7} style={{verticalAlign: "middle", border: 0}}>
                                <Row className="align-items-center">
                                    <Col xs="1" sm="1">
                                        <Badge variant={getVariant(match.team1)} className="float-left" style={{fontSize: '1.3rem'}}>{match.team1.wins}</Badge>
                                    </Col>
                                    <Col xs="10" sm="10">
                                        <h4 style={{marginBottom: 0, color: getColor(match.team1)}}>{match.team1.name}</h4>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: 'white'}} className="no-hover">
                            <td colSpan={7} style={{verticalAlign: "middle", border: 0}}>
                                <Row className="align-items-center">
                                    <Col xs="1" sm="1">
                                        <Badge variant={getVariant(match.team2)} className="float-left" style={{fontSize: '1.3rem'}}>{match.team2.wins}</Badge>
                                    </Col>
                                    <Col xs="10" sm="10">
                                        <h4 style={{marginBottom: 0, color: getColor(match.team2)}}>{match.team2.name}</h4>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <TeamView match={match} team={match.team2} name={match.team2.name}/>
                        </tbody>
                    </Table>
                    { match.spectators.length > 0 &&
                        <p className="d-flex align-items-center justify-content-center mb-0 mt-1">
                            <p className="mb-0">Spectators -&nbsp;</p>
                            { match.spectators.map((spectator, index) =>
                                <p className="mb-0">
                                    <a href={"http://steamcommunity.com/profiles/" + spectator}>{spectator}</a>
                                    {index !== match.spectators.length - 1 ? "," : ""}&nbsp;
                                </p>)
                            }
                        </p>
                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}